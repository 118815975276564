import React, { useState, useEffect } from 'react';
import { CiEdit } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { GoDownload } from "react-icons/go";
import { IoMdSearch } from "react-icons/io";

const Superadmin = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [selectedPartnerCode, setSelectedPartnerCode] = useState(null);
  const [addBalance, setAddBalance] = useState(0);
  const [discount, setDiscount] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // State to store sorting order

  const navigate = useNavigate();
  const handleRowClick = (partnercodeSel) => {
    // Save the selected partner code in localStorage with a key like 'selectedPartnerCode'
    localStorage.setItem('selectedPartnerCode', partnercodeSel);
    
    // You can log the value if needed (for debugging)
    console.log(localStorage.getItem('selectedPartnerCode')); 
  
    // Navigate to the desired page
    navigate(`/SuperadminVendor`);
  };
  
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const sessionToken = await fetchSessionToken();
        const role = localStorage.getItem('role');
  
        if (sessionToken && (role === 'admin' || role === 'finance')) {
          setIsAdmin(true);
          
          // Make sure apiUrl is defined here
          const apiUrl = 'https://esimgo.clay.in/api/'; // Replace with your actual API base URL
          const fullApiUrl = `${apiUrl}ESIMGOAdmin/partner_details?flag=1`;
  
          const response = await fetch(fullApiUrl, {
            headers: {
              'Authorization': `Bearer ${sessionToken}`
            }
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
  
          const data = await response.json();
          setPartners(data.partnerlist);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state if needed
      } finally {
        setLoading(false);
      }
    };
  
    fetchPartners();
  }, []);
  

  const fetchSessionToken = async () => {
    let sessionToken = localStorage.getItem('token');
    return sessionToken;
  };

  const handleEyeClick = (partnercode) => {
    setIsModalOpen(true);
    setSelectedPartnerCode(partnercode);
  };

  const handleDiscountClick = (partnercode) => {
    setIsDiscountModalOpen(true);
    setSelectedPartnerCode(partnercode);
  };

  const handleTicketClick = (partnercode) => {
    localStorage.setItem('partnerC', partnercode);
    navigate('/SuperadminTicket');
  };
console.log('selectedPartnerCode',selectedPartnerCode)
const handleSubmit = async () => {
  try {
    const sessionToken = await fetchSessionToken();

    const apiUrl = `${apiUrl}ESIMGOAdmin/updatewallet_balance`;
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        partnercode: selectedPartnerCode,
        addbalance: addBalance,
        updatedby: selectedPartnerCode
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to update wallet balance: ${errorData.message || response.statusText}`);
    }

    alert('Wallet amount updated successfully');
    setIsModalOpen(false);
    setAddBalance(0); // Reset input field
  } catch (error) {
    console.error('Error updating wallet balance:', error);
    // Handle error state if needed
  }
};

  const handleDiscountSubmit = async () => {
    try {
      const sessionToken = await fetchSessionToken();

      const apiUrl = `${apiUrl}ESIMGOAdmin/UpdatePartner_discount`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          discount: discount,
          partnercode: selectedPartnerCode,
          updated_by: selectedPartnerCode
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update discount');
      }
      alert('Discount has been changed successfully');
      // Optionally, update state or handle success scenario
      setIsDiscountModalOpen(false);
      setDiscount(''); // Reset input field
    } catch (error) {
      console.error('Error updating discount:', error);
      // Handle error state if needed
    }
  };


  const convertToCSV = (array) => {
    const headers = [
      "Partner ID",
      "Partner Code",
      "Amount ID",
      "Email",
      "Partner Name",
      "Contact Person",
      "Discount",
      "Balance",
    ];
    const rows = array.map((item) => [
      item.partnerid,
      item.partnercode,
      item.amountid,
      item.email,
      item.companyname,
      item.contactperson,
      item.discount,
      item.balance,
      
    ]);

    let csvContent = "data:text/csv;charset=utf-8," 
      + headers.join(",") 
      + "\n" 
      + rows.map((row) => row.join(",")).join("\n");

    return encodeURI(csvContent);
  };

  const downloadCSV = () => {
    const csvContent = convertToCSV(partners);
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "Manage_partner_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
 // Toggle sorting order (asc <-> desc)
 const toggleSortOrder = () => {
  setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
};
  const filteredPartners = partners.filter(partner =>
    partner.partnercode.toLowerCase().includes(searchTerm.toLowerCase()) ||
    partner.companyname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    partner.email.toLowerCase().includes(searchTerm.toLowerCase())
  
  ).sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.balance - b.balance; // Low to high
    } else {
      return b.balance - a.balance; // High to low
    }
  });
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>You do not have permission to view this page.</div>;
  }

  return (
    <div className="mt-4 p-2 bg-white shadow justify-between items-center rounded-3xl ">
       <div className="flex justify-between  items-center mb-4">
      
  {/* Left side - Title */}
  <h1 className="text-5xl font-bold">Manage Partners</h1>

  {/* Right side - Button and Search */}
  <div className="flex items-center space-x-4">
    {/* Button to download CSV */}
    <button
      onClick={downloadCSV}
      className="  text-[#105255] rounded-md font-bold flex items-center space-x-2"
    >
      <GoDownload size={28} />
      <span>Export</span>
    </button>
    <button
            onClick={toggleSortOrder}
            className="px-4 py-2 bg-[#3a9a8a] text-white rounded-lg"
          >
            Sort Balance {sortOrder === 'asc' ? 'Low to High' : 'High to Low'}
          </button>

    {/* Search Input */}
     <div className="relative">
  <input
    type="text"
    placeholder="Search by partner code, name, or email"
    className="border-2 bg-[#7bc2c5] rounded-full p-2 pl-10 w-96 text-[#105255] placeholder-[#105255]" 
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
    <IoMdSearch size={20} className="text-[#105255]" />
  </div>
</div>

  </div>
</div>
<div className="mt-4 bg-white shadow flex justify-between items-center rounded-md">
  <div className="relative w-full overflow-x-auto">
    {filteredPartners.length > 0 ? (
      <table className="min-w-full bg-white rounded-md h-96">
        <thead className="bg-gray-50">
          <tr>
            <th className="text-center px-2 py-2">Partner Code</th>
            <th className="text-center px-2 py-2">Email</th>
            <th className="text-center px-2 py-2">Partner Name</th>
            <th className="text-center px-2 py-2">Contact Person</th>
            <th className="text-center px-2 py-2">Partner Type</th>
            <th className="text-center px-2 py-2">Created Date</th>
            <th className="text-center px-2 py-2">Discount</th>
            <th className="text-center px-2 py-2">Balance</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-400">
          {filteredPartners.map((partner) => (
            <tr
              key={partner.partnerid}
              className="cursor-pointer hover:bg-gray-100"
              onClick={() => handleRowClick(partner.partnercode)}
            >
              <td className="text-center px-2 py-2">{partner.partnercode}</td>
              <td className="text-center px-2 py-2 w-2">{partner.email}</td>
              <td className="text-center px-2 py-2 w-2">{partner.companyname}</td>
              <td className="text-center px-2 py-2 w-2">{partner.contactperson}</td>
              <td className="text-center px-2 py-2 w-2">{partner.role}</td>
              <td className="text-center px-2 py-2">{partner.createddate}</td>
              <td className="text-center px-2 py-2">{partner.discount}%</td>
              <td className="text-center px-2 py-2">${partner.balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div className="text-red-500 font-bold text-center px-2 py-2 mt-4">
        Partner code, partner name, or email does not match.
      </div>
    )}

    {/* Modal for updating wallet balance */}
    {isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-4 rounded">
          <h2 className="text-xl mb-4">Update Wallet Balance</h2>
          <input
            type="number"
            className="border p-2 mb-4 w-full"
            value={addBalance}
            onChange={(e) => setAddBalance(Number(e.target.value))}
            placeholder="Enter amount to add"
          />
          <button
            className="bg-blue-500 text-white rounded mr-2"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="bg-red-500 text-white rounded"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    )}

    {/* Modal for updating discount */}
    {isDiscountModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-4 rounded">
          <h2 className="text-xl mb-4">Update Discount</h2>
          <input
            type="text"
            className="border p-2 mb-4 w-full"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            placeholder="Enter discount"
          />
          <button
            className="bg-blue-500 text-white rounded mr-2"
            onClick={handleDiscountSubmit}
          >
            Submit
          </button>
          <button
            className="bg-red-500 text-white rounded"
            onClick={() => setIsDiscountModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    )}
  </div>
</div>

    </div>
  );
};

export default Superadmin;